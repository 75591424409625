var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "white", "padding-bottom": "50px" } },
    [
      _vm._m(0),
      _c("div", { staticClass: "container", attrs: { id: "container" } }, [
        _vm._m(1),
        _c("div", { staticClass: "tab-content confirm" }, [
          _vm._m(2),
          _vm._m(3),
          _c(
            "div",
            { staticClass: "bl_buttonBox" },
            [
              _c(
                "a",
                {
                  staticClass: "btn_notagree",
                  attrs: { href: "https://house.lowenergy.jp/new_beta" }
                },
                [_vm._v(" 同意しない ")]
              ),
              _c(
                "router-link",
                { staticClass: "btn_agree", attrs: { to: "/form" } },
                [_vm._v(" 同意する ")]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "parallaxScroll ly_parallaxScroll_outer" },
      [
        _c(
          "div",
          { staticClass: "parallaxScroll_inner ly_parallaxScroll_inner" },
          [
            _c(
              "div",
              {
                staticClass:
                  "parallaxScroll_child ly_parallaxScroll_child styleObjectCommand"
              },
              [
                _c("div", { staticClass: "header_prg styleObjectHeaderprg" }, [
                  _c("span", [
                    _vm._v("住宅消費エネルギー"),
                    _c("br"),
                    _vm._v("用途分解プログラム")
                  ])
                ]),
                _c("p", [
                  _vm._v(
                    "月々の電気使用量などから12か月のエネルギー消費量を予測します。"
                  )
                ])
              ]
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bl_flexContainer" }, [
      _c("div", { staticClass: "bl_flexContainer_child_content" }, [
        _c("h3", [_vm._v("このプログラムでできること")]),
        _c("p", [
          _vm._v(
            " 電力会社から送付される月々の電力使用量のお知らせなどに記載されている月ごとのエネルギー量を入力すると、どの用途にどのくらいエネルギーが使用されているのか計算し表示します。基本情報として世帯人数、床面積の変更が可能な他、エネルギー源として電気・都市ガス・LPG・灯油を選択できます。 "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "confirm-header" }, [
      _c("div", { staticClass: "title" }, [_c("h4", [_vm._v("使用許諾条件")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "confirm-body" }, [
      _c("p", [
        _vm._v(
          "下記の使用許諾条件を確認し同意の上、「使用許諾条件に同意する」ボタンをクリックして下さい。"
        )
      ]),
      _c("ol", [
        _c("li", [
          _vm._v(
            "本プログラムの利用者（以下単に「利用者」といいます。）は、本プログラムを無料で使用することができます。ただし、その際の通信料等は利用者が負担するものとします。"
          )
        ]),
        _c("li", [
          _vm._v(
            "利用者等が本プログラムの全部又は一部を修正、改変すること、及びリバースエンジニアリング、逆コンパイル又は逆アセンブル等により解析することを禁止します。"
          )
        ]),
        _c("li", [
          _vm._v(
            "利用者等が本プログラムの全部又は一部を通信回線等により頒布すること、媒体の如何を問わず複製し第三者に譲渡、販売、貸与、使用許諾することを禁止します。"
          )
        ]),
        _c("li", [
          _vm._v(
            "国立研究開発法人建築研究所（以下「建研」といいます。）及び一般財団法人建築環境・省エネルギー機構と民間企業との共同研究組織「住宅・非住宅建築物の省エネルギー･脱炭素･室内環境のための技術体系に関する研究」（以下「プログラム開発元等」又は「自立循環プロジェクト」と総称します。）は、理由の如何を問わず、利用者に事前の通知等をすることなく、本プログラムの全部又は一部を変更、追加、削除等（本プログラムの提供の一時的な停止を含みます。以下同じ。）することができるものとします。"
          )
        ]),
        _c("li", [
          _vm._v(
            "プログラム開発元等は、本プログラムについて、その完全性、正確性、確実性その他いかなる事項に関する保証も行わないものとします。"
          )
        ]),
        _c("li", [
          _vm._v(
            "プログラム開発元等は、利用者が本プログラムを使用したことに伴っていかなる損害、損失等が生じたとしても、これらについて一切の保証責任及び賠償責任を負わないものとします。"
          )
        ]),
        _c("li", [
          _vm._v(
            "プログラム開発元等は、第４項により本プログラムの全部又は一部が変更、追加、削除等されたことに伴っていかなる損害、損失等が生じたとしても、これらについて一切の保証責任及び賠償責任を負わないものとします。"
          )
        ]),
        _c("li", [
          _vm._v(
            "プログラム開発元等は、本プログラムを本サービスで明記する注意制限事項等で特定する以外の機器又はプログラムと組み合わせること、また、第２項に反して本プログラムを改変すること等に起因して生じた損害、損失等について一切の責任を負わないものとします。"
          )
        ]),
        _c("li", [
          _vm._v(
            "本プログラムを使用する際に入力する内容や本プログラムにより出力される計算結果に関する情報は、自立循環プロジェクトにおける住宅・建築物の省エネ性能に関する調査、試験、研究及び開発を目的として、個人や個別の建築物が特定されないようにした上でプログラム開発元等が検討する建築物の省エネ性能に関する調査、試験、研究及び開発の参考に収集・管理・利用されます。"
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }